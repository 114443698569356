module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"maison49","defaultLang":"fr"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"maison-du-49","short_name":"49","start_url":"/","background_color":"#FBF8EE","theme_color":"#FBF8EE","display":"minimal-ui","icon":"src/images/icon-site-light.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
